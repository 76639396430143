import {Typography} from "@mui/material";
import React, {useCallback, useMemo, useState} from 'react';
import NextButton from "../../components/NextButton/NextButton";
import Page from "../../components/Page/Page";
import {useFunctions, useFunctionsModule} from "../../data/FirebaseContext";
import pages from "../../data/pages";

import sellerImage from "./images/1.jpg";
import giftCardProductGroup from "./images/2.jpg";
import giftCardProducts from "./images/3.jpg";

const SentrumBuyInstructionsView = ({ data, goToPage, resetData }) => {
    const functions = useFunctions();
    const functionsModule = useFunctionsModule();

    const text = useMemo(() => {
        if (data.productId === 'vl'){
            return `Velg "Gavekort vippeløft" (650,-)`;
        }

        if (data.productId === 'vlf'){
            return `Velg "Gavekort vippeløft med farge" (890,-)`;
        }

        if (data.productId === 'vl2'){
            return `Velg "Gavekort vippeløft med farge for to" (1390,-)`;
        }

        if (data.productId === 'belop'){
            return `Velg "Gavekort valgfritt beløp" og skriv inn ${(data.price / 100).toString()}`
        }

        return "";
    }, [data]);

    const { code } = data;
    const [pending, setPending] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const handleNext = useCallback(() => {
        let cancel = false;
        setPending("Jobber...");

        (async () => {
            if (cancel) return;

            try {
                const confirmCodePurchase = functionsModule.httpsCallable(functions, 'confirmCodePurchase');
                const { data } = await confirmCodePurchase({ code: code });

                if (data.error) {
                    setErrorMessage(data.message);
                    setPending(null);
                } else {
                    console.log("Confirmation successful, returning to start page.");
                    goToPage(pages.startPage);
                    resetData();
                }
            } catch (err){
                console.error(err);
                setErrorMessage("Teknisk feil. Kontakt Tobias.");
                setPending(null);
            }
        })();

        return () => {
            cancel = true;
        };
    }, [goToPage, functions, functionsModule, code, resetData]);

    return(
        <Page>
            <Typography variant={"h5"}>1. Dersom kunden har fått en behandling, ta betalt for behandlingen først.</Typography>
            <br/>
            <Typography variant={"h5"}>2. Velg gavekort-selgeren på kassen.</Typography>
            <img src={sellerImage} alt={"1"}/>
            <br/>
            <br/>
            <br/>
            <Typography variant={"h5"}>3. Velg gavekort i produktmenyen til høyre</Typography>
            <img src={giftCardProductGroup} alt={"2"}/>
            <br/>
            <br/>
            <br/>
            <Typography variant={"h5"}>4. { text }</Typography>
            <img src={giftCardProducts} alt={"3"}/>
            <br/>
            <br/>
            <br/>
            <Typography variant={"h5"}>5. Ta betalt på vanlig måte</Typography>
            <NextButton onClick={handleNext} disabled={Boolean(pending)}>
                Klikk her når kunden har betalt
            </NextButton>
            { pending ? <Typography textAlign={"center"}>{ pending }</Typography> : null }
            { errorMessage ? <Typography textAlign={"center"}>{ errorMessage }</Typography> : null }
        </Page>
    )
};

export default SentrumBuyInstructionsView;