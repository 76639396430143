import {Paper, TextField, Typography} from "@mui/material";
import React, {useCallback, useMemo, useState} from 'react';
import NextButton from "../../components/NextButton/NextButton";
import Page from "../../components/Page/Page";
import {useFunctions, useFunctionsModule} from "../../data/FirebaseContext";
import './enter-code.css';
import pages from "../../data/pages";

const EnterCodeView = ({ data, setData, goToPage, venue }) => {
    const functions = useFunctions();
    const functionsModule = useFunctionsModule();

    const code = data.code || "";
    const updateCode = useCallback(event => {
        const c = event.target.value;
        setData(prevVal => ({
            ...prevVal,
            code: c
        }));
    }, [setData]);

    const [pending, setPending] = useState(false);
    const [giftcard, setGiftcard] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const checkBalance = useCallback(() => {
        if (!functionsModule) return;
        let cancel = false;

        setPending(true);
        (async () => {
            if (cancel) return;
            const getBalance = functionsModule.httpsCallable(functions, 'getCardBalance');
            const { data } = await getBalance({code: code});

            if (data.error){
                console.error(data.message);
                setErrorMessage(data.message);
                return;
            }

            console.log(data.data);
            setGiftcard(data.data);
            setPending(false);
        })();

        return () => {
            cancel = true;
        };
    }, [code, functions, functionsModule]);

    const pendingDisplay = useMemo(() => {
        if (!pending) return null;

        return <Typography textAlign={'center'}>Jobber...</Typography>;
    }, [pending]);

    const notConfirmed = useMemo(() => {
        if (!giftcard) return null;
        if (giftcard.purchaseConfirmed) return null;

        return(
            <Typography>Denne koden har blitt opprettet, men betalingen ble ikke registrert i systemet. Kanskje noen glemte å trykke "Klikk her når kunden har betalt" når gavekortet ble solgt. Om du er i tvil kan du ringe Tobias.</Typography>
        )
    }, [giftcard]);

    const updateWithdrawalAmount = useCallback(event => {
        const stringVal = event.target.value;

        let val;
        try {
            val = parseInt(stringVal, 10) * 100;
        } catch (err){
            console.error(err);
            val = 0;
        }

        setData(prevVal => ({
            ...prevVal,
            w: val
        }));
    }, [setData]);

    const balance = useMemo(() => {
        if (notConfirmed || !giftcard) return null;

        let b = giftcard.reservationRequestData.price;
        if (giftcard.withdrawals && giftcard.withdrawals.length > 0){
            giftcard.withdrawals.forEach(w => {
                b -= w.amount;
            });
        }

        return(
            <div>
                <Paper elevation={5} className={'balance-paper'}>
                    <Typography variant={'h5'} textAlign={'center'}>Gavekortet har en saldo på: { b / 100 },-</Typography>
                </Paper>
            </div>
        )
    }, [notConfirmed, giftcard]);



    const handleNext = useCallback(() => {
        if (venue === 'sentrum'){
            goToPage(pages.sentrumPayInstructions);
        } else {
            goToPage(pages.grunerlokkaPayInstructions);
        }
    }, [goToPage, venue]);

    return(
        <Page>
            <Typography variant={'h4'} textAlign={"center"}>1. Skriv inn koden og trykk "sjekk saldo"</Typography>
            <br/>
            <div className={'text-field-wrapper'}>
                <div className={'text-field-width'}>
                    <TextField
                        fullWidth
                        label={"Gavekort-kode"}
                        value={code}
                        onChange={updateCode}
                        InputProps={{
                            className: 'text-field'
                        }}
                    />
                </div>
            </div>
            <NextButton
                onClick={checkBalance}
            >
                Sjekk saldo på gavekortet
            </NextButton>
            { pendingDisplay }
            { errorMessage }
            <br/>
            { notConfirmed }
            { balance }
            <br/>
            <br/>
            <Typography variant={'h4'} textAlign={'center'}>2. Skriv inn hvor mye som skal trekkes fra gavekortet</Typography>
            <br/>
            <div className={'text-field-wrapper'}>
                <div className={'text-field-width'}>
                    <TextField
                        fullWidth
                        label={"Hvor mye skal kunden betale?"}
                        value={ data.w === null ? "" : (data.w / 100).toString() }
                        onChange={updateWithdrawalAmount}
                        type={'number'}
                        InputProps={{
                            className: 'text-field'
                        }}
                    />
                </div>
            </div>
            <br/>
            <NextButton
                onClick={handleNext}
                disabled={!(giftcard?.purchaseConfirmed && data.w > 0)}
            />
        </Page>
    )
};

export default EnterCodeView;