import React, {useContext, useEffect, useState} from 'react';

const FirebaseContext = React.createContext({});

const FirebaseContextProvider = ({ children }) => {
    const [data, setData] = useState({
        firebase: null,
        firestore: null,
        auth: null,
        app: null
    });

    useEffect(() => {
        (async () => {
            const firebasePrm = import('firebase/app');
            const firestorePrm = import('firebase/firestore');
            const authPrm = import('firebase/auth');
            const functionsPrm = import('firebase/functions');
            const [firebase, firestoreModule, authModule, functionsModule] = await Promise.all([firebasePrm, firestorePrm, authPrm, functionsPrm]);

            const firebaseConfig = {
                apiKey: "AIzaSyAX67zJ0Nxka8oZ9sQ1n2NY_WMcVSvNf9A",
                authDomain: "benediktevippeextensions-c5f6b.firebaseapp.com",
                databaseURL: "https://benediktevippeextensions-c5f6b.firebaseio.com",
                projectId: "benediktevippeextensions-c5f6b",
                storageBucket: "benediktevippeextensions-c5f6b.appspot.com",
                messagingSenderId: "203544960780",
                appId: "1:203544960780:web:8be5ad95a0be70aac7f329",
                measurementId: "G-27RV5LW597"
            };

            const app = firebase.initializeApp(firebaseConfig);
            const firestore = firestoreModule.getFirestore(app);
            const functions = functionsModule.getFunctions(app, "europe-north1");
            const auth = authModule.getAuth(app);

            setData({
                firebase: firebase,
                firestore: firestore,
                firestoreModule: firestoreModule,
                auth: auth,
                authModule: authModule,
                functions: functions,
                functionsModule: functionsModule,
                app: app,
            });
        })();
    }, []);

    // Handle manual sign-out
    useEffect(() => {
        if (!data.auth) return;

        window.signOut = () => {
            console.log("Signing out...");
            data.authModule.signOut(data.auth).then(() => {
                console.log("Signed out.");
            }).catch(err => {
                console.error("Error signing out.");
                console.error(err);
            });
        };
    }, [data]);

    return(
        <FirebaseContext.Provider value={data}>
            { children }
        </FirebaseContext.Provider>
    )
};

const useFirestore = () => {
    return useContext(FirebaseContext).firestore;
};

const useFirestoreModule = () => {
    return useContext(FirebaseContext).firestoreModule;
}

const useFirebase = () => {
    return useContext(FirebaseContext).firebase;
};

const useAuth = () => {
    return useContext(FirebaseContext).auth;
};

const useAuthModule = () => {
    return useContext(FirebaseContext).authModule;
};

const useFirebaseApp = () => {
    return useContext(FirebaseContext).app;
};

const useFunctions = () => {
    return useContext(FirebaseContext).functions;
}

const useFunctionsModule = () => {
    return useContext(FirebaseContext).functionsModule;
}

export { FirebaseContextProvider, useFirestore, useFirestoreModule, useFirebase, useAuth, useAuthModule, useFirebaseApp, useFunctions, useFunctionsModule };