import React, {useCallback, useState} from 'react';

// Core components
import {Button, TextField} from "@mui/material";
import {useAuth, useAuthModule} from "../data/FirebaseContext";

const LoginView = () => {
    const auth = useAuth();
    const authModule = useAuthModule();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const updateEmail = useCallback(event => {
        setEmail(event.target.value);
    }, []);

    const updatePassword = useCallback(event => {
        setPassword(event.target.value);
    }, []);

    const signIn = useCallback(() => {
        authModule.signInWithEmailAndPassword(auth, email, password).then(() => {
            console.log("Sing-in successful");
        }).catch(err => {
            console.error(err);
        })
    }, [email, password, auth, authModule]);

    return(
        <div style={{padding: "2rem"}}>
            <TextField
                label={"E-mail"}
                onChange={updateEmail}
                value={email}
            />
            <TextField
                label={"Password"}
                onChange={updatePassword}
                value={password}
                type={"password"}
            />
            <Button onClick={signIn}>
                Sign in
            </Button>
        </div>
    )
};

export default LoginView;