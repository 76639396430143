import React, {useEffect, useState} from 'react';

import {Table, TableRow, TableCell, TableBody, TableHead} from "@mui/material";
import './ListStyles.css';
import {useFirestore, useFirestoreModule} from "../../data/FirebaseContext";
import GiftcardListItem from "./GiftcardListItem";

const ListView = () => {
    const [display, setDisplay] = useState(null);
    const db = useFirestore();
    const firestore = useFirestoreModule();

    useEffect(() => {
        if (!firestore) return;

        return firestore.onSnapshot(firestore.collection(db, 'giftcards'), snaps => {
            let rows = [];
            snaps.forEach(snap => {
                rows.push({code: snap.id, ...snap.data()});
            });

            rows = rows.map(row => {
                const rowData = row;
                if (rowData.purchaseConfirmationTs > 10639060432){
                    rowData.purchaseConfirmationTs = rowData.purchaseConfirmationTs / 1000;
                } else {
                }
                return rowData;
            });

            rows = rows.filter(row => row.purchaseConfirmed);
            rows.sort((a, b) => b.purchaseConfirmationTs - a.purchaseConfirmationTs);

            setDisplay(rows.map(row => {
                return(<GiftcardListItem key={row.code} row={row}/>);
            }));
        })
    }, [firestore, db]);

    return(
        <div className={'list-view-wrapper'}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Print label</TableCell>
                        <TableCell>Sent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { display }
                </TableBody>
            </Table>
        </div>
    )
};

export default ListView;