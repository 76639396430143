import React, {useMemo} from 'react';
import TwoOptions from "../../components/TwoOptions/TwoOptions";
import pages from "../../data/pages";

const StartPage = ({ goToPage }) => {
    const options = useMemo(() => {
        return [
            {
                label: "Kunden skal kjøpe et gavekort",
                onClick: () => {
                    goToPage(pages.productSelection);
                }
            },
            {
                label: "Kunden skal betale med et gavekort",
                onClick: () => {
                    goToPage(pages.enterCode);
                }
            }
        ];
    }, [goToPage]);

    return(
        <TwoOptions options={options}/>
    )
};



export default StartPage;