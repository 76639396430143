import {Typography} from "@mui/material";
import React from 'react';
import {FirebaseContextProvider} from "./data/FirebaseContext";
import {SignInContextProvider} from "./data/SignInContext";
import ViewManager from "./views/ViewManager";
import PrintView from "./views/PrintView/PrintView";

function App() {
    if (window.location.pathname === '/print'){
        return <PrintView/>
    }

    return (
        <FirebaseContextProvider>
            <SignInContextProvider>
                <br/>
                <Typography
                    component={'div'}
                    variant={'h5'}
                    textAlign={'center'}
                    style={{
                        backgroundColor: '#2196f3',
                        color: 'white',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem'
                    }}>
                    Dere kan alltid ringe Tobias om dere har spørsmål: 90821081
                </Typography>
                <ViewManager/>
            </SignInContextProvider>
        </FirebaseContextProvider>
    );
}

export default App;
