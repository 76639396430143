import {Typography} from "@mui/material";
import React, {useCallback, useMemo, useState} from 'react';
import NextButton from "../../components/NextButton/NextButton";
import Page from "../../components/Page/Page";
import './buy-instructions.css';
import {useFunctions, useFunctionsModule} from "../../data/FirebaseContext";
import pages from "../../data/pages";

import switchUserButton from './images/b1.jpg';
import switchUserPin from './images/b2.jpg';
import selectProduct from './images/b3.jpg';
import valueImage from './images/b4.jpg';

const valueStyle = {
    backgroundImage: `url(${valueImage})`
};

const GrunerlokkaBuyInstructionsView = ({ data, goToPage, resetData }) => {
    const functions = useFunctions();
    const functionsModule = useFunctionsModule();

    const text = useMemo(() => {
        if (data.productId === 'vl'){
            return `Skriv inn 650`;
        }

        if (data.productId === 'vlf'){
            return `Skriv inn 890`;
        }

        if (data.productId === 'vl2'){
            return `Skriv inn 1390`;
        }

        if (data.productId === 'belop'){
            return `Skriv inn ${(data.price / 100).toString()}`
        }

        return "";
    }, [data]);

    const valueImage = useMemo(() => {
        return(
            <div style={valueStyle} className={"gruner-select-value"}>
                <div className={"gruner-select-value-text"}>{ data.price / 100 }</div>
            </div>
        )
    }, [data]);

    const { code } = data;
    const [pending, setPending] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const handleNext = useCallback(() => {
        let cancel = false;
        setPending("Jobber...");

        (async () => {
            if (cancel) return;

            try {
                const confirmCodePurchase = functionsModule.httpsCallable(functions, 'confirmCodePurchase');
                const { data } = await confirmCodePurchase({ code: code });

                if (data.error) {
                    setErrorMessage(data.message);
                    setPending(null);
                } else {
                    console.log("Confirmation successful, returning to start page.");
                    goToPage(pages.startPage);
                    resetData();
                }
            } catch (err){
                console.error(err);
                setErrorMessage("Teknisk feil. Kontakt Tobias.");
                setPending(null);
            }
        })();

        return () => {
            cancel = true;
        };
    }, [goToPage, functions, functionsModule, code, resetData]);

    return(
        <Page>
            <Typography variant={"h5"}>1. Dersom kunden har fått en behandling, ta betalt for behandlingen først.</Typography>
            <br/>
            <Typography variant={"h5"}>2. Velg fellesbrukeren ved å bruke kode: 1234</Typography>
            <div className={"side-by-side-images-wrapper"}>
                <img src={switchUserButton} alt={"s1"} className={"side-by-side-images"}/>
                <img src={switchUserPin} alt={"s2"} className={"side-by-side-images"}/>
            </div>
            <br/>
            <br/>
            <Typography variant={"h5"}>3. Velg gavekort-kategorien og gavekort-produktet</Typography>
            <img src={selectProduct} alt={"3"}/>
            <br/>
            <br/>
            <Typography variant={"h5"}>4. { text }</Typography>
            { valueImage }
            <br/>
            <br/>
            <Typography variant={"h5"}>5. Ta betalt som normalt</Typography>
            <NextButton onClick={handleNext} disabled={Boolean(pending)}>
                Klikk her når kunden har betalt
            </NextButton>
            { pending ? <Typography textAlign={"center"}>{ pending }</Typography> : null }
            { errorMessage ? <Typography textAlign={"center"}>{ errorMessage }</Typography> : null }
        </Page>
    )
};

export default GrunerlokkaBuyInstructionsView;