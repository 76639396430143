import React from 'react';
import {Button} from "@mui/material";
import "./next-button.css";

const NextButton = ({ onClick, disabled, children }) => {

    return(
        <div className={"next-button-wrapper"}>
            <Button
                className={disabled ? "next-button-disabled" : "next-button"}
                onClick={onClick}
                size={"large"}
                disabled={disabled}
            >
                { children || "Neste" }
            </Button>
        </div>
    )
};

export default NextButton;