import {Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import NextButton from "../../components/NextButton/NextButton";
import Page from "../../components/Page/Page";
import "./giftcard-filler.css";
import {useFunctions, useFunctionsModule} from "../../data/FirebaseContext";
import pages from "../../data/pages";

import frontSideBelop from "../../giftCardImages/belop_Page_1.png";
import backsideVl from "../../giftCardImages/vippeloft_Page_2.png";
import backsideVlf from "../../giftCardImages/vippeloftmedfarge_Page_2.png";
import backsideVl2 from "../../giftCardImages/vippeloftmedfarge2_Page_2.png";
import backsideBelop from "../../giftCardImages/belop_Page_2.png";

const backside = {
    vl: backsideVl,
    vlf: backsideVlf,
    vl2: backsideVl2,
    belop: backsideBelop
};

const GiftCardFillerView = ({ setData, data, venue, goToPage }) => {
    const functions = useFunctions();
    const functionsModule = useFunctionsModule();

    const displayCode = useMemo(() => {
        if (!data.code) return "Laster kode... Vent et øyeblikk";

        return data.code;
    }, [data]);

    const { productId, price } = data;
    const [codeError, setCodeError] = useState(null);
    useEffect(() => {
        if (!functions) return;
        let cancel = false;

        const reserveCode = functionsModule.httpsCallable(functions, 'reserveCode');
        reserveCode({venue: venue, productId: productId, price: price}).then(res => {
            if (cancel) return;

            const response = res.data;
            if (response.error){
                console.error(response);
                setCodeError(response.message);
            } else {
                console.log("Successfully reserved code: ", response);
                setData(prevVal => ({
                    ...prevVal,
                    code: response.code
                }));
            }
        }).catch(err => {
            console.error(err);
            setCodeError("Det oppstod et problem under forsøket med å reservere en ny gavekort-kode. Kontakt Tobias.");
        });

        return () => {
            cancel = true;
        }
    }, [functions, functionsModule, venue, productId, price, setData]);

    const fillerImage = useMemo(() => {
        const yCode = {top: '845px'};
        const yDate = {top: '918px'};
        const ySign = {top: '995px'};

        const date = new Date();
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const yyyy = date.getFullYear().toString();

        const dd = (d < 10) ? `0${d}` : d.toString();
        const mm = (m < 10) ? `0${m}` : m.toString();

        return(
            <div className={"giftcard-image-wrapper"}>
                <div
                    className={"giftcard-image"}
                    style={{backgroundImage: `url(${backside[data.productId]})`}}
                >
                    <div className={"fill-text"} style={yCode}>{ displayCode }</div>
                    <div className={"fill-text"} style={yDate}>{ `${dd}.${mm}.${yyyy}` }</div>
                    <div className={"fill-text"} style={ySign}>Din signatur her</div>
                </div>
            </div>
        )
    }, [data, displayCode]);

    const belopImage = useMemo(() => {
        if (data.productId !== "belop") return null;

        const yPrice = {top: '850px', fontSize: '50px'};
        const text = (data.price / 100).toString() + ",-";

        return(
            <React.Fragment>
                <br/>
                <br/>
                <div className={"giftcard-image-wrapper"}>
                    <br/>
                    <Typography variant={"h4"} gutterBottom>OBS: bruk sprittusj på forsiden</Typography>
                </div>
                <div className={"giftcard-image-wrapper"}>
                    <div
                        className={"giftcard-image-front"}
                        style={{backgroundImage: `url(${frontSideBelop})`}}
                    >
                        <div className={"fill-text"} style={yPrice}>{ text }</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }, [data]);

    const handleNext = useCallback(() => {
        if (venue === 'sentrum'){
            goToPage(pages.sentrumBuyInstructions);
        } else {
            goToPage(pages.grunerlokkaBuyInstructions);
        }
    }, [venue, goToPage]);

    return(
        <Page>
            <Typography variant={"h3"} gutterBottom textAlign={"center"}>Fyll inn gavekortet slik:</Typography>
            { fillerImage }
            { belopImage }
            { codeError ? <Typography className={"red-text"}>{ codeError }</Typography> : null }
            <NextButton
                onClick={handleNext}
                disabled={!Boolean(data.code)}
            />
        </Page>
    )
};

export default GiftCardFillerView;