import {Button, Typography} from "@mui/material";
import React from 'react';
import './two-options.css'

// const exampleOptions = [
//     {label: "Option one", onClick: fn1},
//     {label: "Option two", onClick: fn2}
// ]

const TwoOptions = ({options}) => {
    return(
        <div className={"start-page-button-wrapper"}>
            {
                options.map((option, index) => (
                    <Button
                        className={"start-page-button"}
                        variant={"contained"}
                        onClick={option.onClick}
                        key={`option_${index}`}
                    >
                        <Typography variant={"h4"}>
                            { option.label }
                        </Typography>
                    </Button>
                ))
            }
        </div>
    )
};

export default TwoOptions;