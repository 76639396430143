import {List, ListItem, TextField, Typography} from "@mui/material";
import React, {useCallback, useMemo} from 'react';
import NextButton from "../../components/NextButton/NextButton";
import Page from "../../components/Page/Page";
import './product-selection.css';
import pages from "../../data/pages";

import vlCropped from "../../giftCardImages/vl_cropped.png";
import vl2Cropped from "../../giftCardImages/vl2_cropped.png";
import vlfCropped from "../../giftCardImages/vlf_cropped.png";
import belopCropped from "../../giftCardImages/belop_cropped.png";

const images = {
    vl: vlCropped,
    vlf: vlfCropped,
    vl2: vl2Cropped,
    belop: belopCropped
};

const ProductSelectionView = ({ data, setData, goToPage }) => {
    const selectProduct = useCallback(event => {
        const productId = event.currentTarget.getAttribute('data-product-id');

        let price = 0;
        if (productId === 'vl'){
            price = 65000;
        }

        if (productId === 'vlf'){
            price = 89000;
        }

        if (productId === 'vl2'){
            price = 139000;
        }

        setData(prevData => ({
            ...prevData,
            productId: productId,
            price: price
        }));
    }, [setData]);

    const selectBelop = useCallback(event => {
        const newPriceString = event.currentTarget.value;

        let newPrice;
        try {
            newPrice = parseInt(newPriceString, 10);
        } catch (err){
            console.error(err);
            newPrice = 0;
        }


        setData(prevData => ({
            ...prevData,
            price: newPrice * 100
        }), []);
    }, [setData]);

    const belopSelector = useMemo(() => {
        if (data.productId !== "belop"){
            return null;
        }

        return(
            <React.Fragment>
                <br/>
                <TextField
                    label={"Velg Beløp"}
                    value={(data.price / 100) || ""}
                    onChange={selectBelop}
                />
            </React.Fragment>
        );
    }, [data, selectBelop]);

    const handleNext = useCallback(() => {
        goToPage(pages.giftCardFiller);
    }, [goToPage]);

    const nextDisabled = useMemo(() => {
        if (!data.productId) return true;

        if (data.productId === 'belop' && data.price <= 0) return true;

        return false;
    }, [data]);

    return(
        <Page>
            <Typography variant={"h3"} gutterBottom>Velg gavekort</Typography>
            <List>
                <ListItem
                    button
                    data-product-id={"vl"}
                    onClick={selectProduct}
                    className={data.productId === "vl" ? "product-selection-list-item-selected" : "product-selection-list-item"}
                >
                    Vippeløft: 650,-
                </ListItem>
                <ListItem
                    button
                    data-product-id={"vlf"}
                    onClick={selectProduct}
                    className={data.productId === "vlf" ? "product-selection-list-item-selected" : "product-selection-list-item"}
                >
                    Vippeløft med farge: 890,-
                </ListItem>
                <ListItem
                    button
                    data-product-id={"vl2"}
                    onClick={selectProduct}
                    className={data.productId === "vl2" ? "product-selection-list-item-selected" : "product-selection-list-item"}
                >
                    Vippeløft med farge for to: 1390,-
                </ListItem>
                <ListItem
                    button
                    data-product-id={"belop"}
                    onClick={selectProduct}
                    className={data.productId === "belop" ? "product-selection-list-item-selected" : "product-selection-list-item"}
                >
                    Vippeextensions / kunden vil velge beløp selv
                </ListItem>
            </List>
            { belopSelector }
            { data.productId ? <img src={images[data.productId]} alt={"productImg"} className={"product-image"}/> : null }
            <NextButton onClick={handleNext} disabled={nextDisabled}/>
        </Page>
    )
};

export default ProductSelectionView;