import {Button} from "@mui/material";
import {red} from "@mui/material/colors";
import React from 'react';

const ResetButton = ({ onClick }) => {
    return(
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem'}}>
            <Button
                style={{backgroundColor: red['500'], color: "white"}}
                onClick={onClick}
            >
                Avbryt
            </Button>
        </div>
    )
};

export default ResetButton;