import React, {useCallback, useMemo, useState} from 'react';
import ResetButton from "../components/ResetButton/ResetButton";
import {useSignedIn, useUid} from "../data/SignInContext";
import LoginView from "./LoginView";
import pages from "../data/pages";

const defaultData = {
    mode: null,  // buy/pay
    price: 0,
    productId: null,
    code: null
};

const ViewManager = () => {
    const signedIn = useSignedIn();
    const uid = useUid();

    const [page, setPage] = useState(() => {
        if (window.location.pathname === '/list'){
            return pages.list;
        }

        return pages.startPage;
    });
    const goToPage = useCallback(page => {
        setPage(page);
        window.scrollTo(0,0);
    }, []);

    const [data, setData] = useState(defaultData);
    const venue = useMemo(() => {
        if (uid === "BHe1Rk4zPIPlOclOLDuNP18iSr83"){
            console.log("Signed into Grünerløkka");
            return "grunerlokka";
        }

        if (uid === "j08wYETIYZNS9ZUGFP0by7R3RjR2"){
            console.log("Signed into Sentrum");
            return "sentrum";
        }

        if (uid === "62x3YwsuIbTtYnEblqnhz1BIddJ3"){
            console.log("Signed as tobiaslillestolen@gmail.com, venue as set as sentrum.");
            return "sentrum";
        }

        return null;
    }, [uid]);

    const resetData = useCallback(() => {
        setPage(pages.startPage);
        setData({...defaultData});
    }, []);

    const resetButton = useMemo(() => {
        if (page.component !== pages.startPage.component){
            return <ResetButton onClick={resetData}/>
        } else {
            return null;
        }
    }, [page, resetData]);

    if (!signedIn){
        return (
            <div>
                <LoginView/>
            </div>
        )
    }

    return(
        <React.Fragment>
            <page.component
                goToPage={goToPage}
                setData={setData}
                venue={venue}
                data={data}
                resetData={resetData}
            />
            { resetButton }
        </React.Fragment>
    )
};

export default ViewManager;