import React, {useEffect, useMemo, useState} from 'react';
import {Button, TableCell, TableRow} from "@mui/material";
import {useFirestore, useFirestoreModule} from "../../data/FirebaseContext";
import moment from "moment-timezone";

const GiftcardListItem = ({ row }) => {
    const firestore = useFirestoreModule();
    const db = useFirestore();

    const [sessionData, setSessionData] = useState({});
    useEffect(() => {
        if (!firestore || !row.code) return;

        return firestore.onSnapshot(firestore.query(firestore.collection(db, 'sessions'), firestore.where('code', '==', row.code)), snaps => {
            if (snaps.docs.length === 1){
                setSessionData({id: snaps.docs[0].id, ...snaps.docs[0].data()});
            }
        });
    }, [firestore, db, row]);

    const printLink = useMemo(() => {
        if (!sessionData.customerData) return null;
        return `/print?name=${sessionData?.customerData?.name ?? ''}&line1=${sessionData?.customerData?.address?.line1 ?? ''}&line2=${sessionData?.customerData?.address?.line2 ?? ''}&postal_code=${sessionData?.customerData?.address?.postal_code ?? ''}&city=${sessionData?.customerData?.address?.city ?? ''}`;
    }, [sessionData]);

    const registerSent = () => {
        firestore.updateDoc(firestore.doc(db, `giftcards/${row.code}`), {sent: true});
    };

    const moreInfo = () => {
        console.log(row);
        console.log(sessionData);
    };

    return(
        <TableRow>
            <TableCell>{ moment.unix(row.purchaseConfirmationTs).tz('Europe/Oslo').format('YYYY.MM.DD-HH:mm') }</TableCell>
            <TableCell onClick={moreInfo}>{ sessionData?.customerData?.name ?? ' - ' }</TableCell>
            <TableCell>{ sessionData?.productId ?? ' - ' }</TableCell>
            <TableCell>
                { row.code }<br/>
                { sessionData?.id ?? ' - ' }
            </TableCell>
            <TableCell>
                { sessionData?.customerData?.address?.line1 ?? ' - ' }<br/>
                { Boolean(sessionData?.customerData?.address?.line2) ? <React.Fragment>{ sessionData?.customerData?.address?.line2 }<br/></React.Fragment> : null }
                { sessionData?.customerData?.address?.postal_code ?? ' - '}{" "}{sessionData?.customerData?.address?.city ?? ' - '}
            </TableCell>
            <TableCell>
                { printLink ? <a href={printLink} target={'_blank'}>Print</a> : null}
            </TableCell>
            <TableCell>
                { row.sent ? "Sent!" : <Button onClick={registerSent}>Register sent</Button> }
            </TableCell>
        </TableRow>
    )
};

export default GiftcardListItem;