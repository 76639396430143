import React, {useContext, useEffect, useState} from 'react';
import {useAuth, useAuthModule} from "./FirebaseContext";

const SignInContext = React.createContext({});

const SignInContextProvider = ({ children }) => {
    const [data, setData] = useState({
        signedIn: null,
        uid: null
    });

    const auth = useAuth();
    const authModule = useAuthModule();

    useEffect(() => {
        if (!auth || !authModule){return;}

        return authModule.onAuthStateChanged(auth, user => {
            if (user){
                setData({
                    signedIn: true,
                    uid: user.uid
                });
            } else {
                setData({
                    signedIn: false,
                    uid: null
                });
            }
        });
    }, [auth, authModule]);

    return(
        <SignInContext.Provider value={data}>
            { children }
        </SignInContext.Provider>
    )
};

const useSignedIn = () => {
    return useContext(SignInContext).signedIn;
};

const useUid = () => {
    return useContext(SignInContext).uid;
}

export { SignInContextProvider, useSignedIn, useUid };