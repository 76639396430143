import React, {useMemo} from 'react';
import {Typography} from "@mui/material";

import "./printView.css";

const PrintView = () => {
    const params = useMemo(() => {
        const p = new URLSearchParams(window.location.search);
        return {
            name: p.get('name'),
            line1: p.get('line1'),
            line2: p.get('line2'),
            city: p.get('city'),
            postalCode: p.get('postal_code')
        }
    }, []);

    return(
        <div className={'print-view-wrapper'}>
            { params.name ? <Typography className={'print-text'}>{ params.name }</Typography> : null}
            { params.line1 ? <Typography className={'print-text'}>{ params.line1 }</Typography> : null}
            { params.line2 ? <Typography className={'print-text'}>{ params.line2 }</Typography> : null}
            <Typography className={'print-text'}>{ params.postalCode || 'xxxx' } { params.city || 'yyyy' }</Typography>
        </div>
    )
};

export default PrintView;