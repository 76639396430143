import {Typography} from "@mui/material";
import React, {useCallback, useState} from 'react';
import NextButton from "../../components/NextButton/NextButton";
import Page from "../../components/Page/Page";
import {useFunctions, useFunctionsModule} from "../../data/FirebaseContext";
import pages from "../../data/pages";
import './pay-instructions.css';

import payButton from "./payImages/s1.jpg";
import calcButton from "./payImages/g2.jpg";
import manualButton from "./payImages/g3.jpg";

const SentrumPayInstructionsView = ({ data, goToPage, resetData }) => {
    const functions = useFunctions();
    const functionsModule = useFunctionsModule();

    const [pending, setPending] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const handleNext = useCallback(() => {
        setPending(true);

        (async () => {
            try {
                console.log("Spending card...", data.code, data.w);
                const confirmCodePurchase = functionsModule.httpsCallable(functions, 'spendCard');
                const res = await confirmCodePurchase({code: data.code, amount: data.w});

                if (res.error){
                    setPending(false);
                    setErrorMessage(res.message);
                } else {
                    resetData();
                    goToPage(pages.startPage);
                }
            } catch (err){
                setPending(false);
                setErrorMessage("Det oppstod en teknisk feil, kontakt Tobias.");
            }
        })();
    }, [data, goToPage, resetData, functions, functionsModule]);


    return(
        <Page>
            <Typography variant={'h5'}>1. Legg inn behandlingen på kasseapparatet som normalt. Bruk din egen bruker.</Typography>
            <br/>
            <br/>
            <Typography variant={'h5'}>2. Trykk på betalingsknappen og bruk "Annet"</Typography>
            <img src={payButton} alt={'1'}/>
            <br/>
            <br/>
            <br/>
            <Typography variant={'h5'}>3. Skriv inn {(data.w / 100).toString()}</Typography>
            <br/>
            <br/>
            <Typography variant={'h5'}>4. Dersom gavekortet ikke dekket hele kjøpet ta betalt for resten på vanlig måte. (F.eks. trykk "Terminal" for å ta betalt med kort)</Typography>
            <NextButton
                onClick={handleNext}
                disabled={pending}
            >
                Klikk her når betalingen har gått igjennom på kassen
            </NextButton>
            { errorMessage }
        </Page>
    )
};

export default SentrumPayInstructionsView;