import EnterCodeView from "../views/EnterCodeView/EnterCodeView";
import GiftCardFillerView from "../views/GiftCardFiller/GiftCardFillerView";
import GrunerlokkaBuyInstructionsView from "../views/InstructionsPage/GrunerlokkaBuyInstructionsView";
import GrunerlokkaPayInstructionsView from "../views/InstructionsPage/GrunerlokkaPayInstructionsView";
import SentrumBuyInstructionsView from "../views/InstructionsPage/SentrumBuyInstructionsView";
import SentrumPayInstructionsView from "../views/InstructionsPage/SentrumPayInstructionsView";
import ProductSelectionView from "../views/ProductSelectionView/ProductSelectionView";
import StartPage from "../views/StartPage/StartPage";
import ListView from "../views/ListView/ListView";

const pages = {
    startPage: {
        component: StartPage
    },
    productSelection: {
        component: ProductSelectionView
    },
    giftCardFiller: {
        component: GiftCardFillerView
    },
    grunerlokkaBuyInstructions: {
        component: GrunerlokkaBuyInstructionsView
    },
    sentrumBuyInstructions: {
        component: SentrumBuyInstructionsView
    },
    grunerlokkaPayInstructions: {
        component: GrunerlokkaPayInstructionsView
    },
    sentrumPayInstructions: {
        component: SentrumPayInstructionsView
    },
    enterCode: {
        component: EnterCodeView
    },
    list: {
        component: ListView
    }
}

export default pages;