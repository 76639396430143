import React from 'react';
import './page.css';

const Page = ({ children }) => {
    return(
        <div className={'page'}>
            <div className={'content'}>
                { children }
            </div>
        </div>
    )
};

export default Page;